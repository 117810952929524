
import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
  name: 'tabButtonGroup'
})
export default class tabButtonGroup extends Vue {
  @Prop({ type: Array, default: () => [] }) value!: any[]
  private changeMessageType(key: any) {
    console.log(key)
    this.$emit('changeMessage', key)
  }
}
